@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Jost", sans-serif;
  overflow-x: hidden;
}
.LoginSignUpContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  min-height: 600px;
}

.LoginSignUpBox {
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  max-width: 500px;
  min-height: 420px;
  border: 1px solid #000;
}
.login_signUp_toggle p {
  padding: 20px 20px 0px 20px;
}
.login_signUp_toggle {
  display: flex;
}
.login_signUp_toggle > p {
  color: rgba(0, 0, 0, 0.678);
  transition: all 0.5s;
  cursor: pointer;
  display: grid;
  place-items: center;
  width: 100%;
}
.login_signUp_toggle > p:hover {
  color: #000;
}

.LoginSignUpBox > div > button {
  background-color: #000;
  height: 3px;
  width: 50%;
  border: none;
  transition: all 0.5s;
}

.loginForm,
.signUpForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 16px;
  justify-content: space-evenly;
  transition: all 0.5s;
  width: 100%;
}

.signUpForm {
  /* transform: translateY(-100%) translateX(-100vmax); */
}

.loginForm > div,
.signUpForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}
.loginForm > div > input,
.signUpForm input {
  padding: 10px 16px !important;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  outline: none;
  font-family: Philosopher !important;
  height: 50px;
}
.loginForm > div > input,
.signUpForm input {
  padding: 10px 16px !important;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  outline: none;
  font-size: 16px !important;
  font-family: Philosopher !important;
  height: 50px;
}
input.signUpBtn {
  font-family: 'Jost' !important;
}

.signUpForm svg {
  position: absolute;
  transform: translate(10px, 10px);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}
.loginForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.loginForm > a {
  color: rgba(0, 0, 0, 0.651);
  text-decoration: none;
  align-self: flex-end;
  transition: all 0.5s;
}

.loginForm > a:hover {
  color: black;
}

#registerImage > img {
  width: 3vmax;
  border-radius: 100%;
}
#registerImage > input {
  display: flex;
  padding: 0%;
}

#registerImage > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 5vh;
  border: none;
  margin: 0%;
  font: 400 0.8vmax cursive;
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: rgb(255, 255, 255);
}

#registerImage > input::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

.loginBtn,
.signUpBtn {
  background: #000;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #000;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s;
  height: 50px;
  width: 100%;
  border-radius: 5px;
}
.error-message {
  color: rgba(204, 20, 36, 1);
  padding: 0.5rem;
  text-align: center;
  border: 1px solid rgba(204, 20, 36, 1);
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  display: block;
  margin-bottom: var(--bs-gutter-y);
  width: 100%;
}

.createAccount_n {
  text-align: center;
}

.loginBtn:hover,
.signUpBtn:hover {
  background-color: #fff;
  color: #000;
}

.shiftToLeft {
  transform: translateX(-100%);
  position: absolute;
}
.shiftToNeutral {
  transform: translateX(0%);
}

.shiftToNeutralForm {
  transform: translateX(0%) translateY(0%);
  display: flex;
}

.shiftToRight {
  transform: translateX(100%);
}

@media screen and (max-width: 600px) {
  .LoginSignUpContainer {
    background-color: white;
  }
  .LoginSignUpBox {
    width: 100vw;
    height: 95vh;
  }
  .login_signUp_toggle {
    height: 5vmax;
  }

  .loginForm,
  .signUpForm {
    padding: 16px 0;
  }

  .loginForm > div > input,
  .signUpForm input {
    padding: 2.5vmax 5vmax;
    font: 300 1.7vmax cursive;
  }

  .loginForm > div > svg,
  .signUpForm svg {
    font-size: 2.8vmax;
  }


  #registerImage > img {
    width: 8vmax;
    border-radius: 100%;
  }

  #registerImage > input::file-selector-button {
    height: 7vh;
    font: 400 1.8vmax cursive;
  }

  .loginBtn,
  .signUpBtn {
    padding: 1.8vmax;
  }
}

.logHeader {
  text-align: center;
  margin-bottom: 30px;
}
.loginFormNew {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 500px;
  margin: 0 auto;
}
.registerFormNew {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}
.loginFormNew hr {
  margin: 30px 0px;
}
.forgotPasswordEmail i,
.loginPassword i:hover,
.signUpPassword i:hover {
  opacity: 1;
  cursor: pointer;
}
input[type="password"] {
  -webkit-text-security: disc;
}

.forgotPasswordEmail i,
.loginPassword i,
.signUpPassword i {
  position: absolute;
  right: 12px;
  top: 18px;
  opacity: 0.5;
}
.forgotPasswordEmail,
.loginPassword,
.signUpPassword {
  position: relative;
}
