.forgotPasswordContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 50px 0px;
}

.forgotPasswordBox {
  margin: 0 auto;
  background-color: white;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  max-width: 500px;
}

.forgotPasswordHeading {
  text-align: center;
  color: #000;
}

.forgotPasswordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 16px 0;
  justify-content: space-evenly;
  /* height: 70%; */
  gap: 16px;
  transition: all 0.5s;
}

.forgotPasswordForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.forgotPasswordForm > div > input {
  padding: 10px 16px !important;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  outline: none;
  height: 50px;
  font-size: 16px !important;
  font-family: 'Philosopher' !important;
}

.forgotPasswordForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.forgotPasswordBtn {
  background: #000;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #000;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s;
  height: 50px;
  width: 100%;
  border-radius: 5px;
}

.forgotPasswordBtn:hover {
  background-color: rgb(179, 66, 46);
}

@media screen and (max-width: 600px) {
  .forgotPasswordContainer {
    background-color: white;
  }
  /* .forgotPasswordBox {
    width: 100vw;
    height: 95vh;
  } */

  .forgotPasswordForm {
    height: auto !important;
  }

  .forgotPasswordForm > div > svg {
    font-size: 2.8vmax;
  }

  .forgotPasswordBtn {
    font: 300 1.9vmax;
    padding: 1.8vmax;
  }
}
.logRegis a:hover{
  color: #ff914a;
}
.logRegis a {
  color: #000;
  text-decoration: none;
  font-size: 20px;
}
.logRegis {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 10px;
  align-items: center;
}
.forgotPasswordBox hr {
  margin-top: 22px;
}p.noCode a {
  margin-top: 10px;
}