* {
  margin: 0;
  scroll-behavior: smooth;
}

body {
  /* cursor: url("./images/cursor.png"), auto; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.greenColor {
  color: green !important;
}
.redColor {
  color: red !important;
}

/* profile dropdown */
.adminprof {
  position: relative;
}

.userOptions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff; /* Adjust background color as needed */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add box shadow for a visual effect */
}

.userOptions button {
  width: 100%;
  padding: 8px;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.userOptions button:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}
.adminNotification {
  position: relative;
  color: #fff;
  cursor: pointer;
}
.notificationCount {
  position: absolute;
  top: -8px;
  right: -10px;
  background: #ff914a;
  width: 16px;
  height: 16px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.notificationTooltip {
  position: absolute;
  top: 100%; /* Position below the bell icon */
  left: 50%; /* Center horizontally under the bell icon */
  transform: translateX(-50%); /* Adjust for centering */
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  color: #000;
  min-width: 250px;
}

/* App.css */

/* .alertsPop .modal-content {
  padding: 20px !important;
  border: 1px solid grey;
} */
/* .alertsPop {
  background: none !important;
} */
.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex !important;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}

.fade.alertsPop.modal.show {
  -webkit-animation: cssAnimation 3s forwards;
  animation: cssAnimation 3s forwards;
}
.nohide,
.modal.ssGuide {
  -webkit-animation: none;
  animation: none;
}

/* @keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    pointer-events: none;
    display: none !important;
  }
} */
/* @-webkit-keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100%;
  }
} */

.modal .checkoutForm {
  padding: 30px 0px !important;
  border: none !important;
}
.modal .modal-dialog {
  position: relative;
  height: auto;
  max-height: 100%;
  overflow: auto;
  max-width: 900px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.tab-bar {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.tab-bar button {
  padding: 10px;
  cursor: pointer;
}

.tab-bar button.active {
  background-color: #eee;
}

.tab-content {
  padding: 10px;
}
.successIcon i,
.deleteIcon i {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  font-size: 30px;
}
.deleteIcon i {
  background: #d6000040;
  color: #d60000;
}
.successIcon i {
  background: #0080005e;
  color: #008000;
}
.alertsPop p {
  font-size: 20px;
  text-align: center;
}

.actionBtns {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
}
.actionBtns button {
  flex: 1;
  text-transform: uppercase;
}

.PhoneInputInput {
  border: 0 !important;
  padding: 10px;
}

.PhoneInput {
  display: flex !important;
  align-items: center;
}

.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--player-background, rgb(0 0 0 / 80%));
}

/* Backdrop video styling */
.videoBackdrop {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.backdropVideoContainer {
  width: 20%;
  margin: 0 10px;
  transition: opacity 0.5s ease-in-out, z-index 0.5s;
}

.backdropVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.activeVideo {
  border: 2px solid white;
}

/* Main centered video */
.mainVideoContainer {
  position: relative;
  z-index: 3;
  width: 70%;
  max-width: 486px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
}

.lightboxVideo {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 4;
}

.lightboxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.leftContainer,
.rightContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%; /* Adjust width as needed */
  height: 100%;
}

.backdropVideoContainer {
  margin: 10px 0; /* Adjust margin as needed */
}

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.prevButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}

.arrow-button {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
  background-color: rgba(64, 64, 64, 0.5);
  border-radius: 50%;
}

.glassOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

/* Media query for mobile screens */
@media (max-width: 500px) {
  .leftContainer,
  .rightContainer,
  .glassOverlay {
    display: none;
  }
}

/* Media query for Tab screens */
@media (max-width: 1024px) {
  .mainVideoContainer,
  .glassOverlay {
    max-width: 307px; /* Set max-width to 307px on mobile screens */
    width: 100%; /* Make the main video container full width on mobile screens */
  }
}

/* Media query for Tab screens */
@media (max-width: 360px) {
  .mainVideoContainer,
  .glassOverlay {
    max-width: 220px; /* Set max-width to 307px on mobile screens */
    width: 100%; /* Make the main video container full width on mobile screens */
  }
}

.skeletonLoader {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profImg.skeleton {
  background: #1c1c1c;
  width: 21px;
  height: 20px;
}

.msgBody.skeleton {
  flex-direction: column;
}

.singleMsgSkeleton {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.msgHeaderSkeleton {
  width: 100%;
  height: 50px;
  /* Adjust height as needed */
  background-color: #f0f0f0;
  /* Light gray */
  margin-bottom: 10px;
}

.msgBodySkeleton {
  width: 100%;
  height: 100px;
  /* Adjust height as needed */
  background-color: #f0f0f0;
  /* Light gray */
}

/* Add this CSS to your stylesheets */
.shine-animation {
  width: 100%;
  height: 13px;
  background: linear-gradient(110deg, #1c1c1c 5%, #333538 36%, #252629 47%);
  background-size: 200% 100%;
  animation: shine 1.5s infinite linear;
  border-radius: 8px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.td-n {
  text-decoration: none;
}
