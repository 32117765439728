.loading {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  display: grid;
  place-items: center;
  max-width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
}

.loading > div {
  width: 50px;
  height: 50px;
  border-top: 5px solid #000;
  border-bottom: 5px solid #000;
  border-left: 5px solid #000;
  border-right: 5px solid #fff;
  border-radius: 50%;
  animation: loadingRotate 1s linear infinite;
}

@keyframes loadingRotate {
  to {
    transform: rotateZ(360deg);
  }
}
