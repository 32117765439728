@media (min-width: 1281px) {
  .menuToggle {
    display: none;
  }
}

@media (max-width: 1280px) {
  .headerMenu {
    display: none;
  }

  .navSearch .toggleCategory {
    display: none;
  }
}

@media (min-width: 1000px) {
  .fiveCol .col-lg-2 {
    width: 20%;
  }
}
@media (max-width: 767px) {
  div#root {
    /* padding-top: 152px; */
  }
  .completed-detail li {
    flex: 0 0 100% !important;
  }
  .paymentMethod,
  .cartSummary {
    padding: 14px;
  }
  .cartSummary table tr td {
    padding: 10px 0;
    font-size: 14px !important;
  }
  .loginLeft {
    padding: 40px 16px;
  }
  .loginRight {
    padding: 80px 20px 30px 20px;
  }
  .logRegis a {
    font-size: 16px !important;
  }

  .hoverButtons {
    flex-direction: column;
  }
  .call2Data p {
    padding: 0 10px;
    margin: 0 0 6px 0;
  }
  img.homeBan2 {
    /* min-height: 290px; */
    object-fit: contain;
  }
  .forgotPasswordForm {
    height: auto;
  }
  .modalDummyImage img {
    width: 100%;
  }
  .hideMob {
    display: none;
  }
  .shadeCard a,
  .CustomSizeForm button {
    font-size: 10px;
  }
  .CustomSizeForm table tr td,
  .CustomSizeForm label {
    font-size: 11px;
  }
  .customSizePage {
    padding: 20px 0px;
  }
  .trendingLooks {
    padding-left: 16px;
    padding-right: 16px;
  }
  .askQuestion {
    padding: 10px;
  }
  .cartHeader {
    padding: 20px 0px;
  }
  .singleWishCard img {
    height: auto;
  }
  .accHoverData ul li i {
    font-size: 16px !important;
    position: relative;
    bottom: -3px;
  }
  .accHoverData ul {
    gap: 0 !important;
  }
  .accHoverData {
    right: -74px;
    min-width: 174px;
    left: auto;
  }
  .prodTop,
  .prodTop img {
    height: auto;
  }
  .coltitle {
    border-color: #b4b4b4;
  }
  .mobileMenu .subMenu ul li:first-child {
    border-bottom: 1px solid #e8e8e8;
  }
  .checkoutForm {
    padding-right: 0px;
  }
  .cartTable tr td {
    /* white-space: nowrap; */
    padding: 4px;
  }

  .cartTable tr td:last-child {
    white-space: nowrap;
  }

  .cartProductDetail {
    min-width: 310px;
    padding: 4px;
    gap: 10px;
  }

  .cpImage img {
    height: auto;
  }

  .cartTable {
    overflow: auto;
  }

  .toggleCategory {
    flex-wrap: wrap;
  }

  .productGallery {
    padding-right: 0px;
    flex-direction: column-reverse;
  }

  .loginLeft img {
    margin: 0 auto;
  }

  .vertical-media-section {
    flex-direction: row;
    overflow: auto;
  }

  .newSizeButtons {
    gap: 7px;
  }

  .customizationTabble tr td select {
    width: 150px;
  }

  .productImages {
    min-width: 60px;
  }

  .sidebar {
    background: #f1f1f1;
    padding: 20px;
  }

  .realsContainer {
    padding: 0 0px;
  }

  .newLoginSignup {
    flex-direction: column-reverse;
    text-align: center;
    height: auto;
  }

  .homeSliderMobile .slick-slide img {
    height: auto;
    max-height: 600px;
    object-fit: cover;
    object-position: center;
  }

  header .col-md-7 {
    order: 2;
  }

  .headSearch {
    width: 100%;
  }

  .navSearch ul {
    display: none;
  }

  .homeSlider {
    display: none;
  }

  .slick-dots {
    margin-top: 0px;
  }

  .categoryCard img {
    height: auto;
  }

  .singleCollection img {
    height: auto;
  }

  .collectionData .whiteBtn,
  .catIamge .whiteBtn {
    bottom: 5px;
    left: 5px;
    font-size: 10px;
    padding: 8px 16px;
    font-weight: 600;
  }

  .catName {
    font-size: 18px;
    margin-top: 10px;
  }

  .p-both-50 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .homeSlider .slick-slide img {
    height: auto;
    object-fit: cover;
  }

  .catData {
    padding: 40px 16px 0px 16px;
  }

  .slick-prev {
    left: 16px;
  }

  .slick-next {
    right: 16px;
  }

  .singleTrending img {
    height: auto;
  }

  .singleTestimonial,
  .singleReal,
  .singleTrending,
  .categoryCard {
    margin: 10px;
  }

  .singleCollection .overlay {
    border-radius: 0;
  }

  .singleCollection {
    margin: 3px;
  }

  .collectionData h2 {
    font-family: Philosopher;
    font-size: 18px;
    margin-bottom: 8px;
  }

  .collectionData {
    bottom: 6px;
  }

  .title {
    font-size: 30px;
  }

  .catData {
    text-align: center;
  }

  .categoryCard {
    padding: 0 8px;
    margin: 0;
  }

  .greyBox {
    padding: 16px;
    margin: -39px 16px 0 16px;
  }

  .greyBox h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .singleTrending .productDetails {
    bottom: 0px;
  }

  .productDetails h2 {
    margin-bottom: 10px;
  }

  .callToaction2 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .call2Data h2 {
    font-size: 34px;
  }

  .call2Data h3 {
    font-size: 20px;
  }

  .realsContainer .slick-track .slick-slide:nth-child(even) {
    margin-top: 0;
  }

  /* .singleReal {
    padding: 0 16px;
  } */

  .singleReal svg {
    left: 35px;
  }

  .instagram .greyBox {
    margin: 0;
  }

  .instagram .secTitles {
    padding: 0 16px;
    text-align: center;
  }

  .followInsta {
    flex-direction: column;
    margin: 13px 0 0 0;
  }

  .instapicsGrid {
    gap: 8px;
    padding: 0 16px 30px 16px;
  }

  .instapicsGrid img {
    border-radius: 10px;
  }

  .testiData p {
    font-size: 16px;
    margin: 4px 0 0 0;
  }

  .testiData h2 {
    font-size: 22px;
    margin: 0;
  }

  .testiData {
    text-align: center;
  }

  .singleTestimonial img {
    max-width: 150px;
    margin: 0 auto;
    height: auto;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }

  /* header .col-md-7 {
    display: none;
  } */

  .rightIcons ul li a i,
  .rightIcons ul li a svg {
    font-size: 22px;
    width: 20px;
    height: 20px;
  }

  .rightIcons ul {
    gap: 12px;
  }

  .rightIcons ul li a span {
    width: 14px;
    height: 14px;
    font-size: 10px;
    top: 2px;
    right: -6px;
  }
  .toggleCategory button:hover,
  .toggleCategory button.active {
    background: none;
    color: #000;
  }
  .cartHeader img {
    object-fit: fill;
  }

  .vertical-media-section > .slick-slider > button {
    position: relative;
    text-align: center;
    justify-content: center;
  }

  .vertical-media-section > .slick-slider {
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .vertical-media-section > .slick-slider > .slick-arrow {
    left: 0;
    top: 57px;
    height: 32px;
    margin: 0px 5px;
  }

  .vertical-media-section div:hover {
    transform: scale(1);
  }
}

@media (min-width: 768px) {
  .homeSliderMobile {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .greyBox {
    padding: 34px 29px;
    margin-left: -24px;
  }
  .productImages img,
  .productImages video {
    width: 60px;
    height: 80px;
  }

  .headerMenu {
    display: none;
  }

  .categoryCard img {
    height: 260px;
  }

  .collectionData .whiteBtn,
  .catIamge .whiteBtn {
    bottom: 5px;
    left: 5px;
    font-size: 10px;
    padding: 8px 16px;
    font-weight: 600;
  }

  .catName {
    font-size: 18px;
    margin-top: 10px;
  }

  .p-both-50 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .homeSlider .slick-slide img {
    height: auto;
    object-fit: cover;
  }

  .catData {
    padding: 40px 16px 0px 16px;
  }

  .slick-prev {
    left: 16px;
  }

  .slick-next {
    right: 16px;
  }

  .singleTestimonial,
  .singleReal,
  .singleTrending,
  .singleCollection,
  .categoryCard {
    margin: 10px;
  }

  .collectionData h2 {
    font-family: Philosopher;
    font-size: 18px;
    margin-bottom: 0px;
  }

  .collectionData {
    bottom: 6px;
  }

  .title {
    font-size: 30px;
  }

  .catData {
    text-align: center;
  }

  .categoryCard {
    padding: 0 8px;
    margin: 0;
  }

  /* 
  .greyBox {
    padding: 16px;
    margin: -39px 16px 0 16px;
  } */

  .greyBox h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .singleTrending .productDetails {
    bottom: 0px;
  }

  .productDetails h2 {
    margin-bottom: 10px;
  }

  .callToaction2 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .call2Data h2 {
    font-size: 30px;
  }

  .call2Data h3 {
    font-size: 18px;
  }

  .realsContainer .slick-track .slick-slide:nth-child(even) {
    margin-top: 0;
  }

  /* .singleReal {
    padding: 0 16px;
  } */

  .singleReal svg {
    left: 35px;
  }

  .instagram .greyBox {
    margin: 0;
  }

  .instagram .secTitles {
    padding: 0 16px;
    text-align: center;
  }

  .followInsta {
    flex-direction: column;
    margin: 13px 0 0 0;
  }

  .instapicsGrid {
    gap: 8px;
    padding: 0 16px 30px 16px;
  }

  .instapicsGrid img {
    border-radius: 10px;
  }

  .testiData p {
    font-size: 16px;
    margin: 4px 0 0 0;
  }

  .testiData h2 {
    font-size: 22px;
    margin: 0;
  }

  .testiData {
    text-align: center;
  }

  .singleTestimonial img {
    height: 180px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }

  /* header .col-md-7 {
    display: none;
  } */
  .navSearch ul li {
    margin: 0;
  }

  .rightIcons ul li a i,
  .rightIcons ul li a svg {
    font-size: 22px;
    width: 20px;
    height: 20px;
  }

  .rightIcons ul {
    gap: 12px;
  }

  .rightIcons ul li a span {
    width: 14px;
    height: 14px;
    font-size: 10px;
    top: 2px;
    right: -6px;
  }

  .callToaction .container {
    max-width: 80%;
  }
}
