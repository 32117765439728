@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Philosopher:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* html {
  overflow-x: hidden;
} */
body {
  font-family: "Jost", sans-serif;
  overflow-x: hidden;
}
html {
  overflow-x: hidden;
}

.p-bottom-50 {
  padding-bottom: 50px;
}

.mt-2 {
  margin-top: 20px;
}

.mb-2 {
  margin-bottom: 20px;
}

.p-top-50 {
  padding-top: 50px;
}

.p-both-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.m-both-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

ul {
  list-style: none;
}

p {
  font-size: 15px;
}

.topBar p {
  font-family: "Jost", sans-serif;
}

.topBar {
  background: #000;
  color: #fff;
  text-align: center;
  padding: 10px;
}

header {
  padding: 10px 0px;
  background: #fff;
  /* position: sticky;
  top: 0; */
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: -2px;
}

.navSearch ul li a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  font-family: "Jost", sans-serif;
}

.navSearch ul li {
  display: inline-block;
  padding: 8px;
  margin: 0 16px;
}

.navSearch ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navSearch {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.headSearch input {
  border-radius: 32px;
  background: #f5f5f5;
  border: none;
  padding: 10px 20px;
  width: 100%;
  text-transform: capitalize;
  cursor: pointer;
}

.headSearch {
  position: relative;
  width: 55%;
}

.headSearch label {
  position: absolute;
  right: 16px;
  top: 7px;
}

.rightIcons ul li a {
  color: #000;
  font-size: 26px;
}

.rightIcons ul {
  list-style: none;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.headerMenu {
  /* padding: 4px 0px; */
  background: #f2f4f6;
  position: relative;
}

.menuDropDown:hover .megaMenu {
  opacity: 1;
  pointer-events: all;
}

.megaMenu {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background: #fff;
  padding: 20px 0px;
  filter: drop-shadow(0px 10px 5px rgba(0, 1, 1, 0.1));
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 2;
}

li.menuDropDown a i {
  font-size: 12px;
  transform: translateY(2px);
  margin-left: 3px;
}

.megaMenu ul li a {
  padding: 6px 0 !important;
}

.megaMenu ul {
  padding: 0;
  margin: 0;
}

.megaMenu ul li {
  display: block !important;
}

h2.collTitle {
  font-size: 17px;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.font18 {
  font-size: 18px;
}

.colum2 {
  column-count: 2;
}

.subMenu ul li a:hover {
  color: #ff914a;
}

.subMenu ul li a {
  padding: 16px 10px;
  font-size: 16px;
  color: #000;
  text-decoration: none;
  font-family: "Jost", sans-serif;
  display: block;
}

.subMenu ul li {
  display: inline-grid;
}

.rightNumbs {
  text-align: right;
}

.rightNumbs a {
  font-size: 16px;
  color: #000;
  text-decoration: none;
  font-family: "Jost", sans-serif;
}

.title {
  font-family: Philosopher;
  font-size: 34px;
}

.catData {
  padding: 16% 10% 16% 20%;
}

.outlinedBtn:hover {
  background: #000;
  color: #fff;
}

.whiteBtn:hover {
  background: #000;
  color: #fff;
  border: 1px solid transparent !important;
}

.whiteBtn {
  border: 1px solid #fff;
  padding: 8px 20px;
  display: inline-block;
  color: #000;
  background: #fff;
  text-decoration: none;
}

.fullwidth {
  width: 100%;
}

.position-relative {
  position: relative;
}

.smallLabel {
  font-size: 10px !important;
  position: absolute;
  left: 15px;
  top: -7px;
  background: #fff;
  padding: 0 10px;
  color: grey;
}

span.addTitle {
  display: block;
  color: #000;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
}

.singleAddress p {
  font-size: 15px;
  color: gray;
}

.checkoutForm label span {
  display: block;
  color: #000;
  font-size: 16px;
  text-transform: capitalize;
}

.addBTn:hover {
  color: #000;
}

.addBTn {
  background: none;
  border: none;
  font-size: 15px;
  vertical-align: bottom;
  color: #ff914a;
}

.outlinedBtn {
  height: 46px;
  font-size: 16px;
  border: 1px solid #000;
  padding: 8px 20px;
  display: inline-block;
  color: #000;
  text-decoration: none;
  background: none;
  border-radius: 5px;
  font-family: "Jost", sans-serif;
}
.blackbtn {
  height: 46px;
  font-size: 16px;
  border: 1px solid #000;
  padding: 8px 20px;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  background: #000;
  border-radius: 5px;
  font-family: "Jost", sans-serif;
}

.blackbtn:hover {
  border: 1px solid #000;
  color: #000;
  background: #fff;
}
.catData a {
  margin-top: 20px;
}

.categoryCard img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.sale_Slider ul {
  display: flex;
  gap: 40px;
  justify-content: center;
}

.catIamge {
  position: relative;
}

.catIamge .whiteBtn {
  position: absolute;
  bottom: 20px;
  left: 10px;
}

.CatLink {
  text-decoration: none;
}

.catName {
  font-family: Philosopher;
  font-size: 25px;
  color: #000;
}

.sale_Slider ul li p {
  color: #fff;
}

.sale_Slider {
  background: #000;
  padding: 10px 0px;
}

.secTitles {
  text-align: center;
  margin-bottom: 30px;
}

.singleCollection {
  position: relative;
}

.collectionData {
  position: absolute;
  bottom: 35px;
  width: 100%;
  text-align: center;
  color: #fff;
}

.collectionData p {
  margin-bottom: 16px;
}
.collectionData h2 {
  font-family: Philosopher;
  font-size: 22px;
  margin-bottom: 0px;
}

.callToaction .container {
  max-width: 1050px;
}

.gap16 {
  gap: 16px;
}

.greyBox h2 {
  margin-bottom: 20px;
}

.greyBox {
  background: #f3f3f3;
  height: 100%;
  padding: 100px 60px;
  margin-left: -76px;
  text-align: center;
}

.loginLeft img {
  margin-bottom: 40px;
  width: 100%;
  max-width: 160px;
  display: block;
}

.singleTrending {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.singleTrending:hover .productDetails {
  bottom: 0px;
}

.productDetails {
  position: absolute;
  bottom: -92px;
  width: 100%;
  padding: 20px;
  color: #fff;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.productDetails p {
  margin: 8px 0px;
}

.singleTrending:hover .productDetails h2 {
  margin-bottom: 0;
}

.productDetails h2 {
  font-family: Philosopher;
  margin-bottom: 30px;
  font-size: 22px;
  transition: all 0.4s ease-in-out;
  text-shadow: 4px 4px 10px #000;
}

.slashed {
  opacity: 0.7;
  text-decoration: line-through;
  font-weight: 400;
  font-size: 70%;
}

p.price {
  font-size: 18px;
  font-weight: 600;
}

.singleTrending:hover .overlay {
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 0px 0px 10px 10px;
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 30%);
  transition: all 0.4s ease-in-out;
  top: 0;
  left: 0;
}

.callToaction2 {
  position: relative;
}
/* .homeBan2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.callToaction2 .container {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.call2Data h2 {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Philosopher;
  font-size: 78px;
  font-weight: 400;
}

.call2Data {
  text-align: center;
  color: #fff;
  margin: 0;
}

.call2Data p {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.call2Data h3 {
  font-family: "Jost", sans-serif;
  font-size: 30px;
  font-weight: 400;
}

.singleReal svg {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 50px;
}

.singleReal img {
  width: 100%;
}

.singleReal {
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  flex: 1;
}

.realsContainer .slick-track .slick-slide:nth-child(even) {
  margin-top: 70px;
}

/* .realsContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;
} */
.homeSlider .slick-slide img {
  height: auto;
  object-fit: cover;
}

.slick-slide img:focus-visible {
  outline: 0;
}

.slick-dots li button:before {
  display: none !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
}

.slick-dots li button {
  background: #d9d9d9;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  width: 10px;
  height: 10px;
}

.slick-dots li {
  width: auto;
  height: auto;
}

li.slick-active button {
  width: 30px !important;
  border-radius: 5px !important;
  background: #000 !important;
}

.slick-next:before,
.slick-prev:before {
  display: none;
}

.homeSlider .slick-arrow {
  top: auto;
  right: auto;
  bottom: -24px !important;
}

.slick-arrow:focus,
.slick-arrow {
  width: 36px;
  height: 36px;
  background-repeat: no-repeat !important;
  background-size: 10px !important;
  background-position: center center !important;
  border: 1px solid #000;
  border-radius: 50%;
  background: #fff;
  transition: all 0.3s ease-in-out;
  z-index: 10;
}

.slick-arrow:hover {
  background-repeat: no-repeat !important;
  background-size: 10px !important;
  background-position: center center !important;
  background: #f7f7f7;
  scale: 1.09;
}

.homeSlider .slick-prev {
  left: 42% !important;
}

.homeSlider .slick-next {
  left: 55% !important;
}

.slick-prev {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3e%3cg clip-path='url(%23clip0_176_330)'%3e%3cpath d='M1.2 6L7.2 12L8.4 10.8L3.6 6L8.4 1.2L7.2 0L1.2 6Z' fill='black'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_176_330'%3e%3crect width='12' height='12' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e") !important;
}

.slick-next {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none'%3e%3cg clip-path='url(%23clip0_176_332)'%3e%3cpath d='M11.3 6L5.3 12L4.1 10.8L8.9 6L4.1 1.2L5.3 0L11.3 6Z' fill='black'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_176_332'%3e%3crect width='12' height='12' fill='white' transform='matrix(-1 0 0 1 12.5 0)'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e") !important;
}

.homeSlider {
  position: relative;
}

.slick-dots {
  position: static;
  margin-top: 15px;
}

.singleReal:hover,
.singleTrending:hover,
.singleCollection:hover,
.categoryCard:hover {
  scale: 1.03;
}

.singleTestimonial,
.singleReal,
.singleTrending,
.singleCollection,
.categoryCard {
  margin: 13px;
  transition: all 0.3s ease-in-out;
}
.singleTestimonial {
  border: 1px solid rgb(225 225 229);
  border-radius: 10px;
  padding: 20px;
}
.testiData {
  text-align: center;
}
.conCard {
  border: 1px solid rgb(225 225 229);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 16px;
}
.conCard h4 {
  text-transform: uppercase;
}
.conCard a {
  color: #000;
  text-decoration: none;
}

.categorySlider .slick-next {
  right: 16px;
}

.testiData p {
  color: rgb(0 0 0);
  opacity: 0.7;
  font-family: "Jost", sans-serif;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 16px;
}

.testiData h2 {
  color: #222;
  font-family: Philosopher;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}

.colTitle {
  color: #fff;
  font-family: Philosopher;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px;
  margin-bottom: 20px;
}

.footcol ul li {
  display: flex;
  gap: 10px;
}

.footcol ul li a,
.numbers a {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
  text-decoration: none;
}

.numbers {
  display: flex;
  flex-direction: column;
}

.footcol p i {
  margin-right: 10px;
}

.footcol p a,
.footcol p {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  text-decoration: none;
}

.footcol ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

ul.social li a {
  font-size: 20px;
}

ul.social {
  flex-direction: row;
  gap: 20px;
}

.instagram .greyBox {
  padding: 0 0px !important;
}

.instagram .secTitles {
  text-align: left;
  padding: 40px;
}

.followInsta {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
}

.intsaName h2 {
  margin: 0;
  font-family: "Jost", sans-serif;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
}

.followInsta button:hover {
  background: #fff;
  color: #000;
}

.followInsta button {
  background: #000;
  border: 1px solid #000;
  padding: 10px 30px;
  height: 50px;
  color: #fff;
  font-size: 18px;
  font-family: "Jost", sans-serif;
  font-weight: 700;
}

footer {
  background: #000;
  color: #fff;
}

footer hr {
  margin: 30px 0;
  border: none;
  height: 0.5px;
  background: #fff;
}

.footLogo img {
  max-width: 50px;
}

.footLogo {
  display: flex;
  align-items: center;
  gap: 10px;
}

ul.footMenu li a {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
}

.categoryBreadcrum {
  background: #f6f6f8;
  padding: 8px 0;
  /* margin-top: -5px; */
}

.pagelinks ul li span {
  margin-left: 5px;
}

.pagelinks ul li a {
  color: #000;
  text-decoration: none;
  font-size: 15px;
}

.pagelinks ul {
  display: flex;
  gap: 10px;
}

h2.sidebarTitle {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  border-bottom: 1px solid #ff914a;
}

.rating {
  display: flex;
  gap: 5px;
}

.rating ul {
  display: flex;
  color: #fdbc00;
  font-size: 15px;
}

h2.productTitle {
  color: #000;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
}

.hoverButtons a:hover {
  background: #fff;
}

.hoverButtons a {
  padding: 10px 9px;
  background: #ffffffc9;
  text-align: center;
  flex: 1;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 125% */
  text-decoration: none;
}

.hoverButtons {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  position: absolute;
  bottom: -100px;
  width: calc(100% - 20px);
  left: 10px;
  transition: all 0.3s ease-in-out;
}

a {
  cursor: pointer;
}

.prodTop {
  position: relative;
  overflow: hidden;
}

.singleCatProduct {
  margin-bottom: 20px;
  cursor: pointer;
}

.singleCatProduct:hover .hoverButtons {
  bottom: 17px;
}

p.productPrice {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

span.RatingCount {
  color: #666;
  font-size: 15px;
}

.gridTop {
  margin-bottom: 16px;
}

.prodTop img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.gridTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sBy {
  border-radius: 65px;
  background: #f1f1f1;
  border: none;
  padding: 8px;
  color: #000;
  font-family: Jost;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.25px;
  border-right: 20px solid #f1f1f1;
  cursor: pointer;
}

.coltitle {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.5px;
  /* 175% */
  border-bottom: 1px solid #ececec;
  margin-bottom: 16px;
  padding: 7px 0;
  position: relative;
}

.coltitle:after {
  width: 57px;
  height: 2px;
  background: #ff914a;
  position: absolute;
  left: 0;
  bottom: -1px;
  content: "";
}

.singleFilter input {
  height: 20px;
  width: 20px;
  accent-color: #ff914a;
}

.singleFilter label {
  cursor: pointer;
}

.singleFilter {
  display: flex;
  align-items: center;
  gap: 12px;
}

.filterWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filterCol {
  margin-bottom: 10px;
}

span.pCount {
  color: #999;
  font-weight: 300;
}

.newlyStyle h3 {
  color: #333;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  /* 180% */
}

.newlyStyle ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.newlyStyle h2 {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  /* 150% */
  margin-bottom: 8px;
}

.newlyStyle {
  border: 2px solid #e5e5e5;
  padding: 20px;
}

.faqBox {
  border: 2px solid #e5e5e5;
  padding: 20px;
  margin-top: 40px;
  background: #fff;
}

.faqItem {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.faqAnswer {
  padding: 16px;
  background: #f9f9f9;
}

.faqBox h2 {
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  /* 150% */
}

.promoMsg p {
  color: #333;
}

.promoMsg h2 {
  color: #222;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  /* 150% */
}

.promoMsg {
  border-top: 1px solid #e5e5e5;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 40px;
}

.catBottomLinks {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pagination {
  margin-top: 50px;
}

.catBottomLinks a {
  border: 1px solid #000;
  padding: 10px 20px;
  color: #000;
  text-decoration: none;
}

.pagiWrap {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.pagiWrap ul {
  display: flex;
  gap: 10px;
}

.pagiWrap ul li a {
  width: 40px;
  height: 40px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000;
  border-radius: 50%;
}

.pagiWrap ul li a.active {
  background: #000;
  color: #fff;
}

.SingleProductDetails select {
  border: 1px solid rgb(0 0 0 / 14%);
  background: #fff;
  width: 100%;
  height: 50px;
  padding: 10px 20px;
}

p.proPrice {
  font-size: 25px;
}

h2.proName {
  color: #121212;
  font-family: Philosopher;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.offPercent {
  color: #ee001c;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SingleProductDetails label {
  margin-bottom: 4px;
  display: block;
}

.productColorVariations .varPic img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}

.productColorVariations .varPic {
  text-align: center;
}

.addWish a,
.addWish button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.productColorVariations {
  display: flex;
  gap: 16px;
}

.varPic p {
  color: #000;
}

.quantity .number input {
  width: 100%;
}

.quantity .number {
  border: 1px solid rgb(0 0 0 / 14%);
  display: flex;
  justify-content: space-between;
}

span.minus,
span.plus,
.quantity input[type="text"] {
  display: inline-block !important;
  vertical-align: middle;
  text-align: center;
}

.quantity .number input {
  width: 100%;
  width: 50px;
  height: 50px;
  border: none;
}

span.minus,
span.plus {
  /* background: red; */
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 12px 0;
  cursor: pointer;
}

.addWish a i,
.addWish button i {
  margin-right: 10px;
}

.addWish a:hover,
.addWish button:hover {
  background: #000;
  color: #fff;
}

.addWish a,
.addWish button {
  border-radius: 8px;
  border: 1px solid #000;
  color: #000;
  text-align: center;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  height: 50px;
  padding: 6px 30px;
  background: #fff;
  cursor: pointer;
}

.SingleProductDetails .social {
  display: flex;
}

.SingleProductDetails .social li a {
  color: #000;
}

.relatedProducts .singleCatProduct {
  padding: 10px;
}

.prodReview {
  background-image: url(../src/images/Frame.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* category-accordian */
.coltitle {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

/* faq accordian */

.faqQuestion {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin: 2px;
}

/* single product image zoom */
/* Add your general styling here */

.vertical-media-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.vertical-media-section div {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  width: 100%;
}

.vertical-media-section div:hover {
  transform: scale(1.1);
}

.right-tab-or-modal {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  /* Adjust the width as needed */
  /* height: 400px; */
  background: white;
  /* overflow: auto; */
}

.right-tab-or-modal img,
.right-tab-or-modal video {
  width: 100%;
  height: auto;
}

/* Optionally, add styles for content inside the right tab/modal */
.right-tab-or-modal p {
  margin-bottom: 10px;
}

/* Optionally, add styles for close button or other controls in right tab/modal */
.right-tab-or-modal .close-button {
  /* Remove or adjust this block to hide or style a close button if needed */
  display: none;
}

.productGallery {
  display: flex;
  gap: 16px;
  padding-right: 50px;
  position: sticky;
  top: 13vw;
  z-index: 1;
}

.productImages {
  overflow: hidden;
  position: relative;
}

.productImages img,
.productImages video {
  position: relative;
  top: 0;
  left: 0;
  width: 76px;
  height: 110px;
  object-fit: cover;
  border: 2px solid #000;
}

.selected-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Maintain aspect ratio and cover the entire container */
}
div#root {
  /* padding-top: 140px; */
}
.stickyTop {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
}

.searchResults {
  width: 100%;
  z-index: 9;
  filter: drop-shadow(0px 10px 5px rgba(0, 1, 1, 0.1));
  background: #fff;
  position: absolute;
  top: 37px;
  top: 100%;
  display: none;
}

.singleResult a:hover {
  background: #f2f4f6;
}

.prdPrice {
  width: 170px;
}

.prdPrice p {
  font-family: "Jost", sans-serif;
  text-align: right;
}

.singleResult a {
  padding: 10px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  color: #000;
  text-decoration: none;
}

h3.prdTitle {
  font-size: 16px;
  font-weight: 500;
}

.prodData {
  display: flex;
  align-items: center;
  gap: 10px;
}

.prodData img {
  width: 55px;
  height: 70px;
  object-fit: cover;
}

.viewAllResult a {
  text-align: center;
  display: block;
  color: #000;
  text-decoration: none;
  border-top: 1px solid #d8d8d8;
  padding: 8px;
}

.addCartBtns button {
  flex: 1;
  height: 50px;
  font-size: 15px;
  font-family: jost;
  font-weight: 500;
  text-transform: uppercase;
}

.addCartBtns {
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 500px;
}

.addBag:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}

.addBag {
  border-radius: 5px;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  transition: all 0.3s ease-in-out;
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

button.bNow:hover {
  background: #fff;
  color: #000;
}

button.bNow {
  height: 50px;
  text-transform: uppercase;
  border-radius: 5px;
  background: #000;
  color: #fff;
  border: 1px solid #000;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.reviewSingle {
  background: #f4f4f4;
  padding: 16px;
}

.revHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.revUserData img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.revUserData {
  display: flex;
  align-items: center;
  gap: 10px;
}

.oRate i.fa-solid,
.revRating i.fa-solid {
  color: #fdbc00;
}

.oRate i.fa-regular,
.revRating i.fa-regular {
  color: #9d9d9d;
}

.revBody {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #ccc;
}

.askQuestion {
  padding: 40px;
  margin-top: 30px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #fff;
}

.quesBtns {
  border-bottom: 1px solid#EBEAEA;
}

.quesBtns button {
  color: #000;
  text-align: center;
  font-family: Philosopher;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  background: none;
  padding: 16px;
  border: none;
}

.quesBtns button.active {
  border: 2px solid;
}

.quesTabData {
  padding: 16px;
}

.askQuestion textarea {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  width: 100%;
  font-family: Jost;
  padding: 16px;
}

.askQuestion input[type="number"],
.askQuestion input[type="email"],
.askQuestion input[type="text"] {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  width: 100%;
  height: 45px;
  padding: 16px;
  font-family: Jost;
}

.quesTabData button[type="submit"]:hover {
  background: #fff;
  color: #000;
}

.quesTabData label {
  color: #000;
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block;
}

.quesTabData button[type="submit"] {
  background: #000;
  height: 40px;
  width: 200px;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #000;
  font-family: "Jost";
  margin-top: 16px;
}

.footerTop {
  background: #eee;
  padding: 30px 0px;
}

.ftCard p {
  font-size: 18px;
  font-family: Jost;
}

.ftCard i {
  font-size: 40px;
  margin-bottom: 10px;
}

.ftCard {
  text-align: center;
}

/* ========Cart CSS============== */

.cartTable tr th {
  font-size: 15px;
  text-align: left;
  padding: 10px 16px;
  white-space: nowrap;
}

.cartTable tr td {
  font-size: 15px;
  text-align: left;
  padding: 16px;
}

.cpImage img {
  width: 80px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #dee2e6;
}

.cpdata h2 {
  font-size: 15px;
  margin-bottom: 10px;
  display: block;
}

.cpdata p b {
  font-weight: 500;
}

.cpdata a {
  font-size: 15px;
  margin-bottom: 6px;
  display: block;
  color: #000;
  font-weight: 500;
  text-decoration: none;
}

.cartProductDetail {
  display: flex;
  gap: 20px;
}

.remoBtns a i {
  font-size: 12px;
}

.remoBtns a:hover {
  color: #ea0000;
}

.remoBtns a {
  color: #313131;
  font-size: 15px;
  text-decoration: none;
}

.remoBtns {
  display: flex;
  gap: 16px;
  margin-top: 10px;
}

.cartTable {
  border: 1px solid #dee2e6;
}

thead th {
  border-bottom: 1px solid #dee2e6;
}

.cartTable .quantity .number {
  display: inline-flex;
}

.cartTable span.minus,
.cartTable input,
.cartTable span.plus {
  width: 40px !important;
  height: 40px !important;
  padding: 7px 8px;
  cursor: pointer;
}

.cartHeader h2 {
  font-size: 40px;
  text-transform: uppercase;
  z-index: 1;
  position: relative;
  text-shadow: 2px 2px 10px #000;
}

.cartHeader img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cartHeader {
  position: relative;
  overflow: hidden;
  /* background-image: url(../src/images/banner2.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 80px 0px;
  color: #fff;
}

.cartSummary tr:last-child td,
.cartTable tr:last-child td {
  border: none;
}

.createAcc {
  text-align: right;
}

a.contiShop:hover {
  text-decoration: none;
}

a.contiShop {
  margin-top: 16px;
  display: inline-block;
  color: #000;
  font-size: 18px;
}

.paymentMethod {
  margin-top: 16px;
}

.paymentMethod,
.cartSummary {
  background: #f9f9f9;
  padding: 20px;
}

.paymentMethod button,
.cartSummary button {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.cartSummary a {
  color: #000;
  text-decoration: none;
}

.checkoutForm input,
.checkoutForm select {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  height: 45px;
  padding: 6px 16px;
  width: 100%;
  font-size: 15px;
  color: #000;
}

.checkoutForm label {
  display: block;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  color: grey;
}

.checkoutForm {
  /* border: 1px solid #dee2e6;
  padding: 16px; */
  padding-right: 50px;
}

.checkoutForm form {
  margin-top: 10px;
}

.retCust p a {
  color: #ea0000;
}

.retCust p {
  background: #fcfdfd;
  padding: 10px;
  border: 1px solid #dee2e6;
}

.singlePaymet img {
  max-width: 180px;
}

.singlePaymet {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 0px;
}

.singlePaymet img {
  height: 40px;
}

.singlePaymet input {
  width: 16px;
  height: 16px;
  transform: translateY(3px);
}

.singlePaymet label {
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
}

.rightIcons ul li a span {
  position: absolute;
  top: -6px;
  font-size: 12px;
  background: #000;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 50px;
  right: -11px;
  color: #fff;
}

.rightIcons ul li a {
  position: relative;
}

.createAcc a i {
  font-size: 15px;
  margin-right: 9px;
}

a.ccSize {
  color: #000;
}

.modalDummyImage {
  text-align: center;
  position: sticky;
  top: 180px;
}

.CustomSizeForm table tr td {
  padding: 0;
}
.contLeftData h2 {
  margin-bottom: 10px;
}
.bgcust {
  background-image: url(../src/images/contact-us-bg-img_1.jpg);
  background-size: 100% 46%;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 50px 0px !important;
}

.customSizePage {
  background-image: url(../src/images/measurement-bg3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  padding: 80px 0px;
}

.CustomSizeForm {
  background: #fff;
  padding: 5px 0;
}

.CustomSizeForm h4 span {
  color: #ea0000;
}

.CustomSizeForm h4 {
  font-size: 12px;
}

.CustomSizeForm h2 {
  font-size: 14px;
  margin-bottom: 6px;
}

.proCImage img {
  width: 100%;
}

.CustomSizeForm label {
  font-size: 14px;
}

.CustomSizeForm table {
  table-layout: fixed;
  width: 100%;
}

.CustomSizeForm select,
.CustomSizeForm input {
  width: 100%;
  max-width: 60px;
  height: 25px;
  margin-left: 5px;
  padding: 3px 3px;
}

.CustomSizeForm div {
  margin: 3px 0 0 0;
}

.CustomSizeForm button:hover {
  border: 1px solid #000;
  color: #000;
  background: #fff;
}

.CustomSizeForm table tr td:nth-child(even) {
  text-align: right;
}

.CustomSizeForm button {
  width: 100%;
  height: 40px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  text-transform: uppercase;
  margin-top: 5px;
  transition: all 0.3s ease-in-out;
}

.policyPages p {
  font-size: 16px;
  margin-bottom: 16px;
}

.policyPages h2 {
  margin-bottom: 16px;
}
.contLeftData {
  padding: 50px;
}
.ContactFormMain {
  padding: 50px;
  margin: 0 auto;
  background: #f0f0f2;
}

.ContactFormMain textarea {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px 16px;
  font-family: "Philosopher";
}

.ContactFormMain input {
  font-family: "Philosopher";
  width: 100%;
  height: 40px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px 16px;
}

.ContactFormMain label {
  width: 100%;
  display: block;
}

.formTop a {
  color: #000;
  text-decoration: none;
}

.formTop {
  text-align: left;
  margin-bottom: 30px;
}

.noFound {
  text-align: center;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instapicsGrid img {
  width: 31%;
  flex: 0 0 31%;
  border-radius: 20px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.instapicsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.myAccount {
  background: #f4f6f8;
}

.accountTabbs ul li button i {
  width: 30px;
}

.accountTabbs button.active-tabs,
.accountTabbs button:hover {
  background: #fff;
  border-color: #fff;
}

.accountTabbs ul li button {
  text-decoration: none;
  color: #000;
  border: none;
  padding: 10px;
  font-size: 16px;
  display: block;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
}

.accountData {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.accountData select,
.accountData input {
  width: 100%;
  height: 44px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 0 16px;
}

.accountData label {
  display: block;
}

.singleAddress:hover {
  background: #f4f6f8;
  cursor: pointer;
}

.chanegDet {
  text-align: right;
}

.tabContent {
  display: none;
}

.tabContent.activeContent {
  display: block;
}

.accountInfo {
  text-align: center;
  margin-bottom: 20px;
}

.accountInfo img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
  border-radius: 50%;
}

.singleAddress input {
  width: 20px;
  height: 20px;
  margin-right: 16px;
}

.singleAddress label {
  cursor: pointer;
  width: 47%;
}

.singleAddress {
  /* display: flex; */
  border: 1px solid hsl(0deg 0% 50%/20%);
  border-radius: 10px;
  gap: 10px;
  padding: 10px 20px;
  min-height: 100px;
  position: relative;
  height: 100%;
}

.DTag {
  background: #04b204;
  color: #fff;
  border-radius: 43px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  position: absolute;
  right: -3px;
  top: -6px;
}

.mt-3 hr {
  background: hsl(0deg 0% 50%/20%);
  height: 1px;
  border: none;
}

.mt-3 {
  margin-top: 0.7rem;
}

.singleAddress a:hover {
  color: #000;
}

.singleAddress a {
  font-size: 15px;
  color: #787878;
  text-decoration: none;
}

.singleWishCard img {
  height: 300px;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
}

.singleWishCard a {
  text-decoration: none;
  color: #000;
}

.singleWishCard p {
  margin-bottom: 10px;
}

.singleWishCard img {
  height: 300px;
}

/* .wishProductList {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
} */
.billShip label {
  cursor: pointer;
}

.billShip input {
  width: 20px;
  height: 20px;
  margin-right: 16px;
}

.billShip {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.retEx a {
  color: #000;
}

.oScroll {
  overflow-x: auto;
}

.logoDiv {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menuToggle i {
  font-size: 22px;
}

.mobileMenu .modal-dialog {
  height: 100vh;
  border-radius: 0;
  max-width: 80%;
  padding: 0;
}

.mobileMenu .modal-content {
  height: 100%;
  border-radius: 0;
  padding: 46px 14px 14px 14px;
  position: relative;
  overflow: auto;
}

.mobileMenu {
  padding: 0;
  z-index: 9999;
  justify-content: flex-start;
}

.mobileMenu .subMenu ul {
  display: flex;
  flex-direction: column;
}

.CloseMenu {
  font-size: 20px;
  float: right;
  position: absolute;
  top: 16px;
  right: 16px;
}

.MuiSlider-root {
  color: #000 !important;
}

.subMenuMobile {
  display: none;
}

.subMenuMobile.show {
  display: block;
}

table.echangeTable tr th {
  text-align: left;
  background: #f5f5f5;
  padding: 16px;
}

.mobileMenu .singleNav {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

.mobileMenu .subMenu ul li a {
  padding: 12px 0;
  font-size: 18px;
}

.mobileMenu .subMenuMobile {
  background: #f9f9f9;
  padding: 11px 11px 11px 30px;
}

.mobileMenu .subMenuMobile ul {
  margin-left: 16px;
  margin-bottom: 15px;
}

/* =======new Login CSS=========== */
.newLoginSignup button {
  height: 50px;
}

.newLoginSignup {
  display: flex;
  /* height: 100vh; */
}

.loginLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(../src/images/sign-up-bg-img.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  flex: 0 0 30%;
  padding: 100px 20px;
}

.loginRight {
  flex: 1;
  position: relative;
  padding: 100px 20px;
  align-items: center;
  display: flex;
}

.createAccount_n button {
  width: 100%;
}

/* .OTPForm .signUpBtn,
.OTPForm {
  margin-top: 16px !important;
} */
.custom_size_video {
  width: 100% !important;
  height: auto;
  aspect-ratio: 16 / 9;
}

.toggleCategory button:hover,
.toggleCategory button.active {
  background: #000;
  color: #fff;
}

.toggleCategory button {
  width: 100%;
  background: none;
  border: none;
  padding: 10px 21px;
  margin: 0 2px;
  font-size: 18px;
}

.singleReal video {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.realsContainer {
  padding: 0 50px;
}

.singleTrending img {
  height: 400px;
  object-fit: cover;
}

li.head_myacc {
  position: relative;
}

li.head_myacc:hover .accHoverData {
  opacity: 1;
  pointer-events: all;
}

.accHoverData {
  position: absolute;
  z-index: 9;
  background: #fff;
  top: 93%;
  left: -10px;
  border-radius: 5px;
  filter: drop-shadow(0px 10px 5px rgba(0, 1, 1, 0.1));
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.accHoverData ul li a:hover {
  color: #ff914a;
}

.accHoverData ul li a {
  cursor: pointer;
}

.accHoverData ul li span {
  padding: 7px 10px;
}
.accHoverData ul li p,
.accHoverData ul li a {
  padding: 7px 10px;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.accHoverData ul {
  min-width: 170px;
  display: flex;
  gap: 0;
  flex-direction: column;
}

.signedIn {
  width: 9px !important;
  height: 9px !important;
  top: 2px !important;
  right: 0px !important;
  background: #00e700 !important;
}

.toggleCategory {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 10px;
}

.toggleCategory .sigtog {
  width: 84px;
}

.toggleCategory .sigtog img {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

span.S_Guide {
  cursor: pointer;
}

.SingleProductDetails .modal {
  z-index: 999;
  padding: 16px;
  margin: 0;
  overflow: auto;
}
.rivewWrapper {
  max-height: 400px;
  overflow-x: hidden;
}
.SingleProductDetails .modal .modal-content img {
  width: 100%;
}

.SingleProductDetails .modal .modal-content {
  width: 100%;
  padding: 0px;
  max-width: 600px;
  margin: 0 auto;
  overflow: auto;
  position: relative;
}

button.closeModal {
  position: absolute;
  right: 7px;
  top: 4px;
  background: none;
  border: none;
  font-size: 24px;
  padding: 10px;
}

.SingleProductDetails .modal .modal-content .tab-bar button.active {
  border-bottom: 3px solid #000;
}

.SingleProductDetails .modal .modal-content .tab-content {
  padding: 10px;
}

.SingleProductDetails .modal .modal-content .tab-bar button {
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
}

.SingleProductDetails .modal .modal-content .tab-bar {
  justify-content: flex-start;
  border-bottom: 1px solid grey;
}

.priceFilter {
  flex-direction: column;
}

.priceFilter p {
  font-size: 13px !important;
}

.sidebar {
  padding-right: 30px;
}

.prodTop:hover img {
  scale: 1.07;
}

.prodTop {
  height: 400px;
}

.newSizeButtons {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  gap: 16px;
}

.newSizeButtons button {
  color: #000;
  background: #fff;
  border: 1px solid rgb(0 0 0 / 14%);
  padding: 10px 14px;
  border-radius: 50px;
  font-size: 15px;
}

.customizationTabble table {
  width: 100%;
}

.customizationTabble tr td {
  font-size: 15px;
  border: none;
  padding: 0 0 5px 0;
}

.customizationTabble tr td select:focus {
  outline: 0;
}

.customizationTabble tr td select {
  cursor: pointer;
  height: auto;
  padding: 10px 12px;
  width: 100%;
  font-size: 15px;
  display: block;
  border-radius: 50px;
}

.SingleProductDetails hr {
  margin: 16px 0;
  border: none;
  background: rgb(0 0 0 / 14%);
  height: 1px;
}

.customizationTabble {
  /* border-top: 1px solid rgb(0 0 0 / 14%);
  border-bottom: 1px solid rgb(0 0 0 / 14%);
  padding: 10px 0px;
  margin: 10px 0px; */
}

.modalFooter a:hover {
  color: #000;
}

.modalFooter a {
  color: #e83647;
  text-decoration: none;
}

.modalFooter h3 {
  margin-bottom: 8px;
  font-size: 15px;
}

.modalFooter p {
  font-size: 12px;
}

.modalFooter {
  background-color: #dbdbdb;
  padding: 20px 40px;
}

.newLoginSignup input {
  font-size: 16px;
}

.socialSignup i {
  width: 50px;
  height: 50px;
  background: #000;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  margin: 0 auto;
}

.socialSignup i:hover {
  background: #000;
  color: #fff;
}

.socialSignup a {
  text-decoration: none;
}

.socialSignup i {
  width: 50px;
  height: 50px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  margin: 0 auto;
}

.byCon a {
  color: #000;
  font-weight: 600;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-container {
  display: flex;
  align-items: center;
  float: right;
}

.loading {
  -webkit-animation: cssAnimation1 2s forwards infinite;
  animation: cssAnimation1 2s forwards infinite;
}
/* .userOrderTable thead {
  position: sticky;
  top: 0;
  background: #fff;
}

.userOrderTable {
  max-height: 400px;
  overflow: auto;
} */

/* .fade.alertsPop.modal.show {
  -webkit-animation: cssAnimation 2.5s forwards; 
  animation: cssAnimation 2.5s forwards;
} */

/* .singleCollection img {
  height: 400px;
} */
.dnone {
  display: none;
}
.newSizeButtons button:hover,
.newSizeButtons button.active {
  background: #000;
  color: #fff;
}

.singleAddressCheck input {
  width: 18px;
  height: 18px;
}

button.applyCop {
  margin: 0;
  height: 37px;
  padding: 0 !important;
  border-radius: 0.375rem;
  border: 1px solid #000;
  background: #000;
  color: #fff !important;
}

.singleAddressCheck {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 16px;
  gap: 16px;
  position: relative;
  border: 1px solid hsl(0deg 0% 50%/20%);
  border-radius: 4px;
  padding: 16px;
  height: 100%;
}

/* .addressesContainer {
  border: 1px #D5D9D9 solid;
  padding: 16px;
} */
/* 
input[type="radio"]:checked + label {
  padding: 10px 16px;
  background-color: #FCF5EE;
  border: 1px solid #FBD8B4;
  border-radius: 5px;
  width: 100%;
} */

input[type="radio"]:checked {
  accent-color: red;
}

.sale_Slider p {
  color: #fff;
}

.productImages svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
}

.categoryCard p {
  color: #000;
}

.filGroup label {
  cursor: pointer;
}

.filGroup input {
  margin-right: 8px;
  width: 18px;
  height: 18px;
  accent-color: #000;
  cursor: pointer;
}

.manualShipping .MuiDialog-paperScrollPaper {
  width: 100%;
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  cursor: pointer;
}

.ssGuide .modal-content {
  background: #fff;
}

.spclInst textarea {
  width: 100%;
  padding: 5px 10px;
  font-family: "Jost";
  font-size: 14px;
  border: 1px solid #dee2e6;
}

.shadeCard a:hover {
  color: #ff914a;
}

.shadeCard a {
  color: #000;
  font-size: 15px;
  font-family: "Jost";
  font-weight: 700;
  text-transform: uppercase;
}

.shadeCard {
  border: none;
  /* padding-top: 10px !important; */
}

.spclInst {
  border: none;
}

/* .sizeWithToolTip {
  position: relative;
} */

.sizeWithToolTip:hover .SizeTip {
  display: block;
}

.SizeTip p {
  font-size: 14px;
}

.SizeTip {
  position: absolute;
  display: none;
  background: #fff;
  bottom: 109%;
  min-width: 80px;
  padding: 10px;
  box-shadow: 0px 0px 10px 0px #0003;
}
.singleTestimonial img {
  height: 260px;
  object-fit: cover;
  border-radius: 5px;
}
.mobileMenu .toggleCategory button {
  padding: 0;
  color: #000;
}

.proCImage {
  margin-top: 8px;
  position: sticky;
  top: 171px;
}

.policyPages .accordion-item {
  margin-bottom: 10px;
  border: 1px solid #ececec;
}

.policyPages .accordion-header:hover {
  background-color: #f9f9f9;
}
.policyPages .accordion-header {
  border: none;
  cursor: pointer;
  padding: 16px;
  background-color: #f7f7f7;
  font-weight: bold;
  position: relative;
  font-size: 16px;
}

.policyPages .accordion-content p {
  margin: 0;
}
.policyPages .accordion-content {
  display: none;
  padding: 16px;
  background-color: #fff;
}

.policyPages .accordion-content.open {
  display: block;
}
.policyPages span.accordion-icon {
  float: right;
}
.cartSummary table tr td:last-child {
  text-align: right;
}
a.ggBack {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #000;
  text-decoration: none;
  font-size: 14px;
}
.cpdata {
  min-width: 220px;
}

.fixedWhatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.fixedWhatsapp a {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: white;
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #28d26a;
  border: 5px solid #53da8767;
}

.totalPrice {
  padding: 20px 0px;
  color: red;
  font-size: 24px;
  font-weight: 700;
}
