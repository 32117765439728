.UserOrderDetails table tr td:nth-child(1),
.UserOrderDetails table tr td:nth-child(2){
  width: 30%;
}
.UserOrderDetails table tr td:last-child{
  border-right: 0;
}
.text-left{
  text-align: left;
}
.userOrderTable tr th {
  padding: 8px 15px !important;
}
.UserOrderDetails table tr td{
  border: none;
  border-right: 1px solid #dee2e6;
  vertical-align: top;
}
.UserOrderDetails table {
  border: 1px solid #dedede;
  border-radius: 10px;
  width: 100%;
}
.success-animation .green-stroke {
  stroke: #7cb342;
}
.UserOrderDetails table tr td .col-6 {
  font-size: 15px;
}
.success-animation .svg-box {
  display: inline-block;
  position: relative;
  width: 150px;
  overflow: hidden;
}
svg {
  fill: currentColor;
}
svg {
  overflow: hidden;
}
.success-animation .checkmark {
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 56px;
  left: 49px;
  width: 52px;
  height: 40px;
}
.order-completed h3 a{
  color: #000;
}
.order-completed h3 {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0 0 1rem;
}
.order-completed h2 {
  font-size: calc(112.5% + 1vw);
  font-weight: 300;
  margin-bottom: 1rem;
}
.order-completed p {
  margin-bottom: .8rem;
  font-size: .9rem;
  color: #888;
}
.order-completed p {
  margin-bottom: .8rem;
  font-size: .9rem;
  color: #888;
}
.completed-detail li {
  -webkit-box-flex: 1;
  flex: 1;
  padding: 1.5rem;
  margin: -1px;
  background: #fff;
  outline: solid 1px #e2e5ec;
}
.completed-detail h4 {
  font-weight: 500;
  font-size: 1rem;
  display: flex;
}
.completed-detail p {
  font-size: .8rem;
}
.completed-detail {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}
.completed-cart {
  margin: 2rem 0;
}
.completed-cart-list {
  max-height: 350px;
  overflow: auto;
}
.list-cart {
  font-size: .9rem;
}

.list-group {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-cart .list-group-item {
  background-color: #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 1rem;
}
.list-cart-checkout .list-group-item {
  background: none;
  border: none;
  padding: 1rem 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.product-profile {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    flex: 1;
    padding-right: 1rem;
    max-width: 400px;
    min-width: 0;
}
.product-profile__thumbnail {
  position: relative;
  margin-right: 15px;
}
.product-profile__thumbnail .product-qty {
  font-size: .7rem;
  font-weight: 500;
  line-height: 1rem;
  white-space: nowrap;
  text-align: center;
  border-radius: 1.75rem;
  background-color: hsla(0, 0%, 44.7%, .9);
  color: #fff;
  min-width: 1rem;
  height: 1rem;
  padding: 0 .1rem;
  position: absolute;
  right: -.25rem;
  top: -.25rem;
  z-index: 1;
}
.list-cart-checkout .list-group-item .img-fluid {
  max-width: 40px;
}

.product-profile__thumbnail img {
  max-width: 50px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
}
.product-profile__data {
  -webkit-box-flex: 1;
  flex: 1;
  min-width: 0;
}
.product-profile__data .title {
  font-size: .88rem;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.order-completed p {
  margin-bottom: .8rem;
  font-size: .9rem;
  color: #888;
}
p.placed i{
  color: #F13627;
  margin-right: 6px;
}
p.placed a{
  color: #000;
  text-decoration: none;
}
.placed span {
  margin-right: 10px;
}
.list-cart-checkout .list-group-item .product-price {
  margin-right: 0;
  padding-top: 0;
  text-align: right;
}
.completed-cart h5,
.sticky-item h5 {
  font-size: calc(112.5% + .2vw);
  line-height: 1.59;
  font-weight: 300;
}
.cart-total {
  font-size: .85rem;
}

.product-profile__data .title a {
  color: #000;
  text-decoration: none;
}

.cart-total .list-group .list-group-item .label {
  color: var(--txt-gray);
  padding: 0;
  font-size: .85rem;
}
.ml-auto, .mx-auto {
  margin-left: auto !important;
  margin-right: 0 !important;
}

.cart-total .list-group .list-group-item {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border: none;
  border-radius: 0;
  padding: .5rem 0;
}


.cart-total .list-group .list-group-item.hightlighted {
  font-weight: 600;
  padding: 1rem 0;
  margin-top: 1rem;
  border-top: 1px solid #e2e5ec;
}
.UserOrderDetails table thead td {
  font-weight: 600;
  white-space: nowrap;
}






