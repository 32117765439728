.root {
  --bs-success-rgb: 25, 135, 84;
}

.adminHeader {
  transition: all 0.5s;
  z-index: 997;
  height: 230px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #0f6989;
  background-image: url(./images/bg-page-section.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px 0;
}

ul.adminMenu {
  display: flex;
  gap: 10px;
}

ul.adminMenu li a {
  color: #fff;
  font-size: 16px;
  padding: 10px;
  text-decoration: none;
}

.adminSearch input {
  width: 200px;
  height: 40px;
  padding: 10px;
  border-radius: 540px;
  border: none;
  color: #000;
}

.adRight {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
}

.adminNotification ul {
  display: flex;
}

.adminNotification ul li a {
  font-size: 22px;
  color: #fff;
  padding: 10px;
}

.adminSearch {
  position: relative;
}

.adminSearch i {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  color: #000;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.adminprof img {
  width: 42px;
  height: 42px;
  border-radius: 50px;
}

.adminprof {
  display: flex;
  color: #fff;
  align-items: center;
  gap: 10px;
}

.adSubmenu li a:hover {
  background: #eef0f8 !important;
  border-radius: 4px;
}

.adSubmenu li a {
  color: #000 !important;
  padding: 10px;
  display: block;
}

.adSubmenu {
  min-width: 160px;
  background: #fff;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
  border-radius: 4px;
  position: absolute;
  top: 32px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.withSubmenu:hover .adSubmenu {
  opacity: 1;
  pointer-events: all;
}

.withSubmenu {
  position: relative;
}

li.withSubmenu a i {
  font-size: 12px;
  margin-left: 6px;
}

main#main {
  margin-top: -130px;
  margin-bottom: 50px;
}

.pagetitle h1 {
  color: #fff;
  font-family: Philosopher;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.3px;
}

table.table.socilMedia {
  width: 100%;
  margin-bottom: 16px;
}

.breadcrumb {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  color: #899bbd;
  font-weight: 600;
  margin: 0;
}

.breadcrumb a {
  color: #fff;
  transition: 0.3s;
}

.breadcrumb .breadcrumb-item::before {
  color: #fff;
}

.breadcrumb .active {
  color: #fff;
  font-weight: 600;
}

.whiteBtn {
  background: #fff;
  color: #000;
  padding: 8px 20px;
  border-radius: 3.25px;
  border: 1px solid #fff !important;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
}

.dashboard .info-card {
  padding-bottom: 10px;
}

.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.dashboard .filter {
  position: absolute;
  right: 0px;
  top: 15px;
  display: none;
}

.card-body {
  padding: 0 20px 20px 20px;
}

.card-body {
  flex: 1 1 auto;
  color: var(--bs-card-color);
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.dashboard .sales-card .card-icon {
  color: #4154f1;
  background: #f6f6fe;
}

.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

@media (min-width: 1400px) {
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}

.rounded-circle {
  border-radius: 50% !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.d-flex {
  display: flex !important;
}

.dashboard .revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}

.ps-3 {
  padding-left: 1rem !important;
}

.dashboard .info-card h6 {
  font-size: 28px;
  color: #000000;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.table > thead > tr > th {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  line-height: 1;
  color: #000;
}

.small,
small {
  font-size: 0.875em;
}

.table.table-borderless {
  max-width: 100%;
  width: 100%;
}

table.table.table-borderless.datatable {
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.dashboard table.table.datatable {
  width: 100%;
}

.dashboard .table thead {
  background: #f6f6fe;
  position: sticky;
  top: 0;
}

.table > thead {
  vertical-align: bottom;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table > tbody {
  vertical-align: inherit;
}

table tr td {
  vertical-align: middle;
  padding: 8px 10px;
  border-bottom: 1px solid #dee2e6;
  box-shadow: inset 0 0 0 9999px transparent;
}

.datatable th a {
  color: #000;
}

.text-primary {
  color: #000 !important;
  text-decoration: none;
}

.adminbg {
  font-family: Jost;
  background: #eef0f8;
  color: #444444;
}

.gap-2 {
  gap: 0.5rem !important;
}

.singBan input {
  width: 150px;
}
.form-control {
  display: block;
  font-family: Philosopher;
  /* text-transform: capitalize; */
  width: 100%;
  height: 46px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select {
  display: block;
  width: 100%;
  height: 46px;
  padding: 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fs-9 {
  font-size: 0.8rem !important;
  color: #000;
  text-decoration: none;
}

.dropzone {
  border: 1px dashed #9fa6bc;
  padding: 3rem 2rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  position: relative;
}

.fw-bold {
  font-weight: 700 !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.dropzone input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: block !important;
  opacity: 0;
}

.text-right {
  text-align: right;
}

.dropzone .btn {
  font-size: 0.8rem;
  border: none;
  background: none;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.adminBreadcrum ul li + li:before {
  content: "/";
  padding-right: 10px;
  color: #fff;
}

.adminBreadcrum ul {
  display: flex;
  gap: 10px;
}

.adminBreadcrum ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 14px;
}

.card-body .card-icon i {
  font-size: 20px;
}

.dashboard .news img {
  width: 80px;
  float: left;
  border-radius: 5px;
}

.dashboard .news img {
  width: 80px;
  float: left;
  border-radius: 5px;
  color: #141824;
}

.dashboard .news p {
  font-size: 14px;
  color: #777777;
  margin-left: 95px;
}

.dashboard .news .post-item + .post-item {
  margin-top: 15px;
}

.dashboard .news h4 a {
  color: #000000;
  text-decoration: none;
  transition: 0.3s;
}

.dashboard .filter .icon {
  color: #aab7cf;
  padding-right: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
  font-size: 16px;
}

.text-danger {
  color: #dc3545;
}

.text-success {
  color: #198754;
}

.bg-success {
  background: #198754;
  border-radius: 0.375rem;
  font-size: 10px;
  padding: 0.35em 0.75em;
  font-weight: 700;
  color: #fff;
}

.bg-warning {
  border-radius: 0.375rem;
  font-size: 10px;
  padding: 0.35em 0.75em;
  font-weight: 700;
  background: #ffc107;
  color: #fff;
}

.bg-danger {
  border-radius: 0.375rem;
  font-weight: 700;
  font-size: 10px;
  padding: 0.35em 0.75em;
  background: #dc3545;
  color: #fff;
}

.FootData p {
  font-size: 14px;
  margin: 10px 0 0 0;
}

.FootData img {
  width: 50px;
}

.FootData {
  text-align: center;
  padding: 10px;
}

button.blackBtn.m-2 {
  margin-right: 10px;
}

.blackBtn {
  background: #000;
  color: #fff;
  padding: 8px 20px;
  height: 46px;
  border-radius: 5px;
  border: 1px solid #fff !important;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
}

.blackbtn:hover {
  border: 1px solid #000;
  color: #000;
  background: #fff;
}

.w100 {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.adminTables tr th {
  background: #f6f6fe;
}

.adminTables tr td,
.adminTables tr th {
  padding: 0.625rem;
  font-size: 14px;
  text-align: left;
}

.actions button i {
  margin-right: 5px;
}

.actions button:hover {
  background: #7a7a7a !important;
  color: #fff !important;
}

.actions button {
  font-size: 13px;
  padding: 7px 12px;
  margin: 0 4px;
  background: none;
  border: none;
  border-radius: 5px;
  color: #fff;
}

button.viewbtn {
  background: #087afa;
}

button.editBtn {
  background: #1ea648;
}

button.editInvBtn {
  background: #217391;
}

button.dltBtn {
  background: #dc3545;
}

.adminTables .CategoryImage {
  width: 60px;
  height: 56px;
  object-fit: cover;
  border-radius: 5px;
}

.categoryFilter {
  margin-top: 20px;
  /* display: flex;
  justify-content: space-between; */
  gap: 10px;
  margin-bottom: 20px;
}

.catiSearch input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  color: #000;
}

/* .currentBanners .row {
  flex-wrap: nowrap;
  overflow: auto;
} */

/* width */
.currentBanners .row::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

/* Track */
.currentBanners .row::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.currentBanners .row::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

/* Handle on hover */
.currentBanners .row::-webkit-scrollbar-thumb:hover {
  background: #c5c5c5;
}

.ModalIamge img {
  width: 100%;
  border: 1px solid #000;
  height: 160px;
  object-fit: contain;
  background: #f4f4f4;
}
.singBan img,
.singBan video {
  width: 100%;
  border: 1px solid #000;
  height: 100px;
  object-fit: contain;
}

.ModalIamge,
.singBan {
  position: relative;
}

.ModalIamge i:hover,
.singBan i:hover {
  color: #ff914a;
}
.ModalIamge i,
.singBan i {
  font-size: 22px;
  color: #000;
  position: absolute;
  top: -12px;
  right: -10px;
  cursor: pointer;
  background: #fff;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.loginleft img {
  width: 100%;
  max-width: 200px;
}

.loginleft {
  background: #0f6989;
  padding: 50px 16px;
  background-image: url(./images/bg-page-section.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  height: 100%;
  color: #fff;
}

.loginPage {
  background: #eef0f8;
}

.h100 {
  height: 100%;
}

.welcomeDiv {
  text-align: center;
  color: #fff;
}

.loginFormContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
}

.loginFormContainer .card {
  max-width: 500px;
}

.text-center {
  text-align: center !important;
}

button.loginBtn:hover {
  background: #fff;
  color: #000;
}

button.loginBtn {
  width: 100%;
  height: 46px;
  background: #000;
  border: 1px solid #000;
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}

p.forPas a:hover {
  text-decoration: none;
}

p.forPas a {
  color: #000;
  margin-top: 5px;
  display: inline-block;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-check label {
  cursor: pointer;
}

.form-check input {
  width: 20px;
  height: 17px;
  margin-right: 10px;
}

.pb-0 {
  padding-bottom: 0;
}

.welcomeDiv h1 {
  font-size: 46px;
}

.SizeVariations a,
.colorVariations a {
  color: #000;
}

img.colorImage {
  width: 80px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.upClor h5 {
  cursor: pointer;
}

.upClor {
  position: relative;
  height: 39px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.upClor input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

a.adColBtn i {
  margin-right: 8px;
}

a.adColBtn {
  background: #000;
  border-radius: 4px;
  padding: 5px 10px;
  color: #fff;
  text-decoration: none;
  float: right;
  font-size: 14px;
  min-width: 120px;
  text-align: center;
}

.productMainImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productMainImage {
  border: 1px dotted grey;
  height: 150px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.galleryImages {
  display: flex;
  gap: 16px;
  margin-bottom: 10px;
}

.galleryImages img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

a.fw-bold.fs-9 {
  color: #005585 !important;
}

.badge-phoenix-info {
  background-color: #c7ebff;
  color: #005585;
  border: 1px solid #96d9ff;
}

.badge-phoenix-danger {
  background-color: #ffe0db;
  color: #b81800;
  border: 1px solid #fabcb3;
}

.badge-phoenix-warning {
  background-color: #ffefca;
  color: #bc3803;
  border: 1px solid #ffcc85;
}

.badge-phoenix-secondary {
  background-color: #e3e6ed;
  color: #141824;
  border: 1px solid #cbd0dd;
}

.badge-phoenix-success {
  background-color: #d9fbd0;
  color: #1c6c09;
  border: 1px solid #bee8b4;
}

.badge-phoenix svg {
  position: absolute;
  right: 6px;
  top: 3px;
}

.badge-phoenix {
  text-transform: uppercase;
  padding: 2px 8px;
  font-size: 0.75em;
  font-weight: 700;
  border-radius: 0.375rem;
  position: relative;
  padding-right: 28px;
}

.avatar {
  position: relative;
  display: block;
}

.avatar-m {
  height: 2rem;
  width: 2rem;
  margin-right: 10px;
}

.adminTables a {
  color: #000;
}

.avatar img,
.avatar .avatar-name {
  width: 100%;
  height: 100%;
}

.avatar img {
  object-fit: cover;
}

.colMainImage {
  position: relative;
}

.colMainImage input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.form-control::placeholder {
  font-size: 14px;
}

.varientWrapper {
  background: #eff1f6;
  padding: 16px;
  border-radius: 10px;
}

.colMainImage label {
  width: 108px;
  height: 100px;
  background: #f9f9f9;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.gallImagess {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  position: relative;
}

.sp_image img {
  height: 150px;
  object-fit: contain;
  border: 1px dashed;
  border-radius: 5px;
}

.variant_images img,
.variant_images video {
  width: 108px;
  height: 100px;
  object-fit: contain;
  border: 1px dashed;
  border-radius: 5px;
}

.variant_images {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.gallImagess span.delImage {
  display: none;
}

.variant_images i {
  right: -1px;
  width: 20px;
  height: 20px;
  background: #000;
  border-radius: 10px;
  top: -5px;
  color: #fff;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

label.ppIamge {
  width: 100%;
}

span.delImage {
  position: absolute;
  top: -7px;
  right: -8px;
  width: 20px;
  height: 20px;
  background: #000;
  color: #fff;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

a.addAnother {
  float: right;
  color: #000;
  margin-top: 10px;
  text-decoration: none;
}

.addMoreSize a {
  float: unset;
  height: 40px;
  display: flex;
  align-items: center;
  min-width: auto;
  cursor: pointer;
}

.varientWrapper hr {
  border: none;
  height: 1px;
  background: #ccc;
}

.addMoreSize {
  display: flex;
  align-items: end;
  height: 100%;
}

.varientWrapper .card-title {
  padding: 0;
  border-bottom: 1px solid #ccc;
}

.p_r {
  position: relative;
}

.p_a {
  position: absolute;
}

.ql-blank {
  height: 220px !important;
}

.catParent {
  padding: 10px;
  background: #176b88;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
}

.catTableBtns {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.main_category {
  /* border: 1px solid #d7d7d7; */
  margin-bottom: 10px;
}

.subsubCat div {
  background: #fff !important;
  padding-left: 40px;
}

.sub_category {
  padding: 10px;
  background: #f2f2f2;
  padding-left: 30px;
}

.confirm {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 99;
  display: none;
}

.logPass i {
  position: absolute;
  right: 21px;
  top: 34px;
  cursor: pointer;
}

.logPass {
  position: relative;
}

.order-details-table table {
  width: 100%;
}

.order-details-table p {
  font-size: 16px;
}

.NoAccess h1 {
  color: #000;
}

.NoAccess {
  max-width: 500px;
  margin: 100px auto;
  display: block;
  padding: 100px 0px;
  border: 1px solid rgb(168, 0, 0);
  border-radius: 20px;
  background: #fff;
  color: #000;
}

.NoAccess i {
  font-size: 50px;
  color: rgb(168, 0, 0);
}

.input-group i {
  position: absolute;
  right: 12px;
  top: 15px;
}

.input-group {
  position: relative;
}

.noOtp p a {
  color: #ff771d;
}

/* =================== */
.onoffswitch {
  position: relative;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.publish_unpublish {
  float: left;
  vertical-align: middle;
  position: relative;
  top: 3px;
}

/* .actions {
  display: flex;
  align-items: center;
} */
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  /* border: 2px solid #999999; */
  border-radius: 20px;
  margin-top: 2px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: "Jost";
  font-weight: 500;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: #1ea648;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: #eeeeee;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 6px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 38px;
  border: 2px solid #999999;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.pSticky {
  background: #217391;
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
  padding: 8px 0px;
}

.size_custom_list table {
  width: 100%;
}

button.eBtn {
  background: rgb(0, 172, 0);
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
}

button.delBtn {
  background: rgb(178, 0, 0);
  color: #fff;
  border-radius: 5px;
}

.size_custom_list button {
  padding: 8px 10px;
  border: none;
}

.size_custom_list table tr td {
  padding: 4px 0;
  font-size: 14px;
}

.tableOflow a {
  color: #217391 !important;
  text-decoration: underline;
}

.tableOflow {
  height: 350px;
  overflow: auto;
}

input[type="date"] {
  text-transform: uppercase;
}
.sizeActions {
  display: flex;
  gap: 10px;
}
.sEdit,
.sDelete {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
}
.sDelete {
  background: #dc3545;
}
.sEdit {
  background: #198754;
}

.sEdit:hover,
.sDelete:hover {
  background: #565656;
}
.budgetTable tr td img {
  max-height: 50px;
}
.custAvail label {
  cursor: pointer;
}
.custAvail input {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
